<template>
    <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
        <template #header>
            <view-search-form :queryInfo.sync="queryInfo" @on-search="renderTable(1)" keyword-placeholder="经办人、申请人、处理用户"
                :tool-list="['keyword']">
                <el-form-item label="合同编号">
                    <el-input v-model="queryInfo.contractNo" placeholder="请输入合同编号" />
                </el-form-item>
                <el-form-item label="合同名称">
                    <el-input v-model="queryInfo.contractName" placeholder="请输入合同名称" />
                </el-form-item>
                <el-form-item label="审核状态">
                    <el-select v-model="queryInfo.contractStatus" clearable filterable size="small">
                        <el-option v-for="( item, index ) in  contractStatusList " :key="index" :label="item[1]"
                            :value="item[0]" />
                    </el-select>
                </el-form-item>
            </view-search-form>
            <el-radio-group v-model="queryInfo.contractStatusListJson" @change="statusListChange">
                <el-radio-button v-for="(item, index) in queryInfoStatusList" :key="index" :label="item.values">{{
                    item.label
                }}
                </el-radio-button>
            </el-radio-group>
            <div class="header-button mb-1">
                <el-button @click="showDialog()" type="primary" size="small">
                    新建合同
                </el-button>
                <el-button type="primary" plain size="small">
                    合同附件下载
                </el-button>
                <el-button type="success" size="small">
                    导出
                </el-button>
            </div>
        </template>
        <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
            <el-table-column type="selection" width="40" :selectable="handleRowSelectable" />
            <el-table-column type='index' label='序号' width='50' />
            <el-table-column prop="contractNo" label="合同编号" min-width="90" />
            <el-table-column prop="contractName" label="合同名称" min-width="90" />
            <el-table-column prop="contractMoney" label="合同金额" min-width="90" />
            <el-table-column prop="contractFile" label="合同附件" min-width="90" />
            <el-table-column prop="supplyUserName" label="申请人" min-width="160" />
            <el-table-column prop="doUserName" label="经办人" min-width="90" />
            <el-table-column prop="returnDate" label="归还时间" min-width="90" />
            <el-table-column prop="contractStatus" label="合同状态" min-width="110">
                <template v-slot="{ row }">
                    <el-tag>{{ contractStatusList[row.contractStatus + 2][1] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createUserName" label="处理用户" min-width="90" />
            <el-table-column prop="applicationTime" label="申请时间" min-width="100" />
            <el-table-column label="操作" min-width="150" fixed="right">
                <template v-slot="{ row }">
                    <el-button v-if="isRole(row.createUserId) && [1].includes(row.contractStatus)" type="text"
                        @click="showDialog(row.id)">
                        修改
                    </el-button>
                    <el-button v-if="isRole(row.createUserId) && [1, -1].includes(row.contractStatus)" type="text"
                        @click="showDialog(row.id)">
                        提交
                    </el-button>
                    <el-button v-if="isRole(row.createUserId) && [2].includes(row.contractStatus)" type="text"
                        @click="del(undoContractByIdApi, row.id, `${row.contractName}`)">
                        撤回
                    </el-button>
                    <el-button type="text" @click="showDialog(row.id, 'onlyRead')">
                        详情
                    </el-button>
                    <el-button
                        v-if="(isRole(row.deptUserId) || isRole(row.leaderUserId) && [2, 3].includes(row.contractStatus))"
                        type="text" @click="showDialog(row.id)">
                        审核
                    </el-button>
                    <el-button v-if="isRole(row.createUserId) && [-2, 1, 2].includes(row.contractStatus)" type="text"
                        @click="del(delContractByIdApi, row.id, `${row.contractName}`)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <dialog-add-contract ref="dialogRef" :visible.sync='visibleDialog' v-if='visibleDialog' @on-close='renderTable' />
    </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import DialogAddContract from './components/DialogAddContract.vue'
import { contractStatusList, queryInfoStatusList } from './components/option'
import { contractPageApi, delContractByIdApi, undoContractByIdApi } from '@/api/contract.js'
export default {
    mixins: [tableView],
    components: {
        DialogAddContract
    },
    mounted() {
        this.renderTable()
    },
    onShow() {
        this.renderTable()
    },
    computed: {
        isRole(roleId) {
            return (roleId) => {
                if (roleId === this.$store.state.login.userInfo.id) {
                    return true
                } else {
                    return false
                }
            }

        }
    },
    watch: {
        visibleDialog: function (newVal, oldVal) {
            if (newVal === false && oldVal === true) {
                this.renderTable()
            }
        }
    },
    data() {
        return {
            contractStatusList,
            queryInfoStatusList,
            queryInfo: {
                pageNum: 1,
                pageSize: 10,
                contractStatus: null,
                contractStatusListJson: '[-1,0,1,2,3,4,6]',
                contractName: null,
                contractNo: null,
            },
        }
    },
    methods: {
        delContractByIdApi,
        undoContractByIdApi,
        // 合同筛选状态改变
        statusListChange(e) {
            this.queryInfo.contractStatusListJson = e
            this.renderTable()
        },
        async renderTable() {
            await this.getTableData(contractPageApi, this.queryInfo.pageNum)
        },
        handleRowSelectable() { }
    }

}
</script>

<style lang="scss" scoped></style>